import { Link } from 'gatsby'
import React from 'react'

import Logo from './me.jpeg'
import * as Icon from 'react-feather';

const Sidebar = ({ siteMetadata }) => (
  <>
    <aside className="sidebar">
      <header>
        <div className="about">
          <div className="cover-author-image">
            <Link to="/">
              <img src={Logo} alt={siteMetadata.author} />
            </Link>
          </div>
          <div className="author-name">{siteMetadata.author}</div>
          <p>{siteMetadata.description}</p>
          <Link className="link" to="/me/">
            ReadMe First
          </Link>
          <br/>
          <br/>
        </div>
      </header>
      <footer>
        <section className="contact">
          <h3 className="contact-title">Ping me</h3>
          <ul>
            {siteMetadata.social.twitter && (
              <li>
                <a
                  href={`https://twitter.com/${siteMetadata.social.twitter}`}
                  target="_blank"
                >
                  <Icon.Twitter size={22}/>
                </a>
              </li>
            )}
            {siteMetadata.social.github && (
              <li>
                <a
                  href={`https://github.com/${siteMetadata.social.github}`}
                  target="_blank"
                >
                  <Icon.GitHub size={22}/>
                </a>
              </li>
            )}
            {siteMetadata.social.instagram && (
              <li>
                <a
                  href={`https://instagram.com/${siteMetadata.social.instagram}`}
                  target="_blank"
                >
                  <Icon.Instagram size={22}/>
                </a>
              </li>
            )}
            {siteMetadata.social.linkedin && (
              <li>
                <a
                  href={`https://linkedin.com/in/${siteMetadata.social.linkedin}`}
                  target="_blank"
                >
                  <Icon.Linkedin size={22}/>
                </a>
              </li>
            )}
            {siteMetadata.social.email && (
              <li>
                <a href={`mailto:${siteMetadata.social.email}`} target="_blank">
                  <Icon.Mail size={22}/>
                </a>
              </li>
            )}
          </ul>
        </section>
        <div className="copyright">
          <p>
            Made with 💻 and ☕️ by {siteMetadata.author} &copy; {new Date().getFullYear()}
          </p>
        </div>
      </footer>
    </aside>
  </>
)

export default Sidebar
